<template>
  <div class="content-accounts-payable-all">
    <div class="content-txt-title-search" v-if="!bMobile && !bTablet">
      <p class="txt-title-accounts-payable">Cuentas por pagar</p>
      <div class="search-filter-accounts-payable">
        <v-text-field
          v-model="sSearch"
          label="Buscar proveedor"
          placeholder="Buscar proveedor"
          class="global-text-field"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
        >
        </v-text-field>
      </div>
    </div>

    <div class="content-accounts-payable-table-card-all">
      <!-- #region Contenedor de cuentas por pagar (MXN) -->

      <!-- #region contenedor de titulo y filtro de cuentas por pagar MXN -->
      <div class="content-txt-title-search" v-if="bMobile || bTablet">
        <p class="txt-title-accounts-payable">Cuentas por pagar MXN</p>
        <div class="search-filter-accounts-payable">
          <v-text-field
            v-model="sSearch"
            label="Buscar proveedor"
            placeholder="Buscar proveedor"
            class="global-text-field"
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </div>
      </div>
      <!-- #endregion contenedor de titulo y filtro de cuentas por pagar MXN -->

      <div
        class="content-accounts-payable-table content-accounts-payable-table-mxn"
      >
        <!-- #region tabla de cuentas por pagar (MXN) -->
        <report-accounts-payable-table
          v-if="!bMobile"
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aTables="aAccountsPayable"
          :numPages="numPages"
          :totalItems="totalItems"
          @getEmitAccountsPayable="getEmitAccountsPayable"
        />
        <!-- #endregion tabla de cuentas por pagar (MXN) -->

        <!-- #region carta de cuentas por pagar (MXN) -->
        <report-accounts-payable-card
          v-else
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aCards="aAccountsPayable"
          :numPages="numPages"
          :totalItems="totalItems"
          @getEmitAccountsPayable="getEmitAccountsPayable"
        />
        <!-- #endregion carta de cuentas por pagar (MXN) -->

        <!-- #region Total de cuentas por pagar (MXN) -->
        <div class="content-footer-total-accounts-payable-all">
          <div class="content-footer-total-accounts-payable">
            <div class="content-footer-txt-price-total-accounts-payable">
              <div class="content-footer-txt-total-accounts-payable">
                <p class="footer-txt-total-accounts-payable">
                  TOTAL DE CUENTAS POR PAGAR
                </p>
              </div>
              <div class="content-footer-price-total-accounts-payable">
                <p class="footer-price-total-accounts-payable">
                  $ {{ formatMoneyGlobal(dTotalPayable) }} MXN
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion Total de cuentas por pagar (MXN) -->
      </div>
      <!-- #endregion Contenedor de cuentas por cobrar (MXN) -->

      <!-- #region Contenedor de cuentas por cobrar (USD) -->

      <!-- #region contenedor de titulo y filtro de cuentas por pagar USD -->
      <div class="content-txt-title-search" v-if="bMobile || bTablet">
        <p class="txt-title-accounts-payable">Cuentas por pagar USD</p>
        <div class="search-filter-accounts-payable">
          <v-text-field
            v-model="sSearch"
            label="Buscar proveedor"
            placeholder="Buscar proveedor"
            class="global-text-field"
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </div>
      </div>
      <!-- #endregion contenedor de titulo y filtro de cuentas por pagar USD -->
      <div
        class="content-accounts-payable-table content-accounts-payable-table-usd"
      >
        <!-- #region tabla de cuentas por pagar (USD) -->
        <report-accounts-payable-table
          v-if="!bMobile"
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aTables="aAccountsPayableUSD"
          :numPages="numPagesUSD"
          :totalItems="totalItemsUSD"
          @getEmitAccountsPayable="getEmitAccountsPayableUSD"
        />
        <!-- #endregion tabla de cuentas por pagar (USD) -->

        <!-- #region carta de cuentas por pagar (USD) -->
        <report-accounts-payable-card
          v-else
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aCards="aAccountsPayableUSD"
          :numPages="numPagesUSD"
          :totalItems="totalItemsUSD"
          @getEmitAccountsPayable="getEmitAccountsPayableUSD"
        />
        <!-- #endregion carta de cuentas por pagar (USD) -->

        <!-- #region Total de cuentas por pagar (USD) -->
        <div class="content-footer-total-accounts-payable-all">
          <div class="content-footer-total-accounts-payable">
            <div class="content-footer-txt-price-total-accounts-payable">
              <div class="content-footer-txt-total-accounts-payable">
                <p class="footer-txt-total-accounts-payable">
                  TOTAL DE CUENTAS POR PAGAR
                </p>
              </div>
              <div class="content-footer-price-total-accounts-payable">
                <p class="footer-price-total-accounts-payable">
                  $ {{ formatMoneyGlobal(dTotalPayableUSD) }} USD
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion Total de cuentas por pagar (USD) -->
      </div>
      <!-- #endregion Contenedor de cuentas por cobrar (USD) -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    aAccountsPayable: Array,
    aAccountsPayableUSD: Array,
    dTotalPayable: Number,
    dTotalPayableUSD: Number,
    bMobile: Boolean,
    bTablet: Boolean,
    numPages: Number,
    numPagesUSD: Number,
    totalItems: Number,
    totalItemsUSD: Number,
  },
  data() {
    return {
      sSearch: "",
      aHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
          value: "sNumber",
          class: "global-header-table header-table-number-account-payable",
          cellClass: "global-body-table body-table-number-account-payable",
        },
        {
          text: "Nombre del proveedor",
          sortable: false,
          value: "sName",
          class: "global-header-table ",
          cellClass: "global-body-table body-table-name-account-payable",
        },
        {
          text: "Rango de tiempo",
          sortable: false,
          value: "sDateRange",
          class: "global-header-table ",
          cellClass: "global-body-table body-table-date-range-account-payable",
        },
        {
          text: "Variación",
          sortable: false,
          value: "dVariation",
          align: "end",
          class: "global-header-table ",
          cellClass: "global-body-table body-table-variation-account-payable",
        },
        {
          text: "Adeudo total",
          sortable: false,
          value: "dTotalDebit",
          align: "end",
          class: "global-header-table ",
          cellClass:
            "global-body-table body-table-payable-total-debit-account-payable",
        },
      ],
    };
  },
  methods: {
    setOrder(oItem) {
      this.$router.push({
        name: "OPDetailReport",
        params: { idOP: oItem.sOrderId },
      });
    },
    getEmitAccountsPayable(obj) {
      this.$emit("getEmitAccountsPayable", obj);
    },
    getEmitAccountsPayableUSD(obj) {
      this.$emit("getEmitAccountsPayableUSD", obj);
    },
  },
  watch: {
    sSearch() {
      this.$emit("setSearchAccountsPayable", this.sSearch);
    },
    aAccountsPayable() {},
  },
};
</script>
<style scoped>
.content-accounts-payable-table-card-all {
  display: flex;
}
.content-accounts-payable-table-mxn {
  width: 50% !important;

  padding-right: 5px;
}
.content-accounts-payable-table-usd {
  width: 50% !important;

  padding-left: 5px;
}
.content-accounts-payable-all {
  /* margin-top: 25px; */
  margin-bottom: 25px;
}
.content-txt-title-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txt-title-accounts-payable {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 18px;
}
.search-filter-accounts-payable {
  width: 350px;
}

.content-footer-total-accounts-payable-all {
  align-items: center;
  width: 100%;
  background-color: var(--primary-color-background-table) !important;
  padding: 5px 20px;
  min-height: 60px;
  max-height: 80px;
  border-radius: 15px;
}
.content-footer-total-accounts-payable {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--primary-color-background-blue-transparent);
  min-height: 50px;
  border-radius: 10px;
  padding: 0px 20px;
}

.content-footer-txt-total-accounts-payable {
  margin-right: 10px;
}
.content-footer-txt-price-total-accounts-payable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.body-table-date-range-account-payable {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.body-table-variation-account-payable {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.footer-txt-total-accounts-payable,
.footer-price-total-accounts-payable {
  font-family: "pop-Bold";
  font-size: 18px;
  color: var(--primary-color-text-white);
  margin-bottom: 0px;
}

/*Plantilla media query css responsive*/

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-accounts-payable-all {
    /* margin-top: 25px; */
    /* margin-bottom: 25px; */
  }
  .content-txt-title-search {
    display: block;
    align-items: center;
    justify-content: space-between;
  }

  .txt-title-accounts-payable {
    color: var(--primary-color-text);
    font-family: "pop-SemiBold";
    font-size: 18px;
  }
  .search-filter-accounts-payable {
    width: 100%;
  }

  .content-accounts-payable-table-card-all {
    display: block;
  }
  .content-accounts-payable-table-mxn {
    width: 100% !important;
    margin-bottom: 25px;
    padding-right: 0px;
  }
  .content-accounts-payable-table-usd {
    width: 100% !important;

    padding-left: 0px;
  }

  .content-footer-total-accounts-payable-all {
    align-items: center;
    width: 100%;
    background-color: var(--primary-color-background-table) !important;
    padding: 5px 10px;
    min-height: 65px;
    max-height: 85px;
    border-radius: 15px;
    margin-top: 20px;
  }
  .content-footer-total-accounts-payable {
    display: block;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--primary-color-background-blue-transparent);
    min-height: 50px;
    border-radius: 10px;
    padding: 5px 10px;
  }

  .content-footer-txt-total-accounts-payable {
    margin-right: 0px;
  }
  .content-footer-txt-price-total-accounts-payable {
    display: block;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .footer-txt-total-accounts-payable,
  .footer-price-total-accounts-payable {
    font-family: "pop-Bold";
    text-align: center;
    font-size: 15px;
    color: var(--primary-color-text-white);
    margin-bottom: 0px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .content-accounts-payable-table-card-all {
    display: block;
  }
  .content-accounts-payable-table-mxn {
    width: 100% !important;
    margin-bottom: 25px;
    padding-right: 0px;
  }
  .content-accounts-payable-table-usd {
    width: 100% !important;

    padding-left: 0px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .content-footer-total-accounts-payable-all {
    align-items: center;
    width: 100%;
    background-color: var(--primary-color-background-table) !important;
    padding: 5px 20px;
    min-height: 60px;
    max-height: 80px;
    border-radius: 15px;
  }
  .content-footer-total-accounts-payable {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--primary-color-background-blue-transparent);
    min-height: 50px;
    border-radius: 10px;
    padding: 0px 20px;
  }

  .content-footer-txt-total-accounts-payable {
    margin-right: 0px;
  }
  .content-footer-txt-price-total-accounts-payable {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .footer-txt-total-accounts-payable,
  .footer-price-total-accounts-payable {
    font-family: "pop-Bold";
    text-align: right;
    font-size: 16px;
    color: var(--primary-color-text-white);
    margin-bottom: 0px;
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
